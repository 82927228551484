import * as React from "react";
import { ListItemModel } from "../../models/ListItemModel";
import WorkListItem from "./work-list-item";

type DataProps = {
  color?: string;
  path: string;
  data: ListItemModel[];
};

const WorkList3 = ({ color, path, data }: DataProps): JSX.Element => {
  const contents = () => {
    const a = [];
    for (let i = 0; i < data.length; i += 3) {
      const item1 = data[i];
      const item2 = data[i + 1];
      const item3 = data[i + 2];

      a.push(
        <div
          key={i}
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <WorkListItem color={color} path={path} item={item1} />
          <WorkListItem color={color} path={path} item={item2} />
          <WorkListItem color={color} path={path} item={item3} />
        </div>
      );
    }
    return a;
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
      {contents()}
    </div>
  );
};

export default WorkList3;
