import * as React from "react";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { IGatsbyImageDataParent } from "gatsby-plugin-image/dist/src/components/hooks";

type DataProps = {
  images: {
    image: {
      localFile: {
        childImageSharp: IGatsbyImageDataParent<IGatsbyImageData>;
      };
    };
  }[];
};

const Images = ({ images }: DataProps): JSX.Element => {
  const [imgTags, setImgTags] = React.useState<JSX.Element[]>([]);
  const [current, setCurrent] = React.useState(0);

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const num = images.length - 1;
    if (num <= 0) {
      setCurrent(0);
      return;
    }
    const x = e.nativeEvent.offsetX;
    const w = e.currentTarget.clientWidth;

    const currentNew = Math.floor(x / (w / num)) + 1;
    const currentOld = current;

    if (currentNew !== currentOld) {
      // console.log(`set current ${currentOld} => ${currentNew}`);
      setCurrent(currentNew);
    }
  };
  const onMouseLeave = () => {
    if (images.length > 0) {
      setCurrent(0);
    }
  };

  React.useEffect(() => {
    setImgTags(
      images.map((v, i) => {
        const image = getImage(v.image.localFile.childImageSharp);
        if (!image) {
          return <></>;
        }

        return (
          <div
            key={i}
            style={{
              opacity: current === i ? "1" : "0",
              // transitionProperty: "opacity",
              // transitionDuration: "0.2s",
            }}
          >
            <GatsbyImage
              image={image}
              alt=""
              objectFit="contain"
              style={{
                width: "100%",
                // height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </div>
        );
      })
    );
  }, [images, current]);

  let h = 0;
  for (let image of images) {
    const tmp = image.image.localFile.childImageSharp;
    h = Math.max(h, tmp.gatsbyImageData.height);
  }
  return (
    <div
      style={{ position: "relative", paddingTop: h * 100 + "%" }}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    >
      {imgTags}
    </div>
  );
};

export default Images;
