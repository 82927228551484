/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Link } from "gatsby";
import MediaQuery from "react-responsive";
import { ListItemModel } from "../../models/ListItemModel";
import Images from "./images";

type DataProps = {
  color?: string;
  path: string;
  item: ListItemModel | null;
  showLabel?: boolean;
};

const WorkListItem = ({
  color = "black",
  path,
  item,
  showLabel = true,
}: DataProps): JSX.Element => {
  const style = css`
    padding-top: 3px;
    color: ${color};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  `;
  const labelStyle: React.CSSProperties = {
    marginTop: "10px",
    display: showLabel ? "block" : "none",
  };

  if (!item || !item.thumbnail) {
    return <div style={{ flex: 1 }}></div>;
  }

  let title = item.title;
  if (process.env.GATSBY_LANG === "EN") {
    if (item.title_en && item.title_en.titleEn) {
      title = item.title_en.titleEn;
    }
  }

  const content = item.thumbnail.thumbnailContent;

  if (content.type === "image") {
    const images = content.images ?? [];
    return (
      <div style={{ flex: 1 }}>
        <Link to={path + item.slug} css={style}>
          <Images images={images} />
          <div style={labelStyle}>{title}</div>
        </Link>
      </div>
    );
  } else if (content.type === "movie") {
    const src = content.movie?.guid ?? "";
    const srcMobile = content.movieMobile ? content.movieMobile.guid : src;
    return (
      <div style={{ flex: 1 }}>
        <Link to={path + item.slug} css={style}>
          <MediaQuery maxWidth={767}>
            <video
              src={srcMobile}
              style={{ width: "100%", display: "block" }}
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </MediaQuery>
          <MediaQuery minWidth={768}>
            <video
              src={src}
              style={{ width: "100%", display: "block" }}
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </MediaQuery>
          <div style={labelStyle}>{title}</div>
        </Link>
      </div>
    );
  }

  return (
    <div style={{ flex: 1 }}>
      <Link to={path + item.slug} css={style}>
        <div style={labelStyle}>{title}</div>
      </Link>
    </div>
  );
};

export default WorkListItem;
