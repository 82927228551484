/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useLocation } from "@reach/router";
import { graphql, navigate } from "gatsby";
import * as React from "react";
import Layout from "../components/layout";
import WorkCategoryItem from "../components/works/work-category-item";
import WorkList from "../components/works/work-list";
import { ListItemModel } from "../models/ListItemModel";

type DataProps = {
  data: {
    allWpWork: {
      nodes: ListItemModel[];
    };
  };
};

const WorkPage = ({ data }: DataProps) => {
  const location = useLocation();

  const [category, setCategory] = React.useState("all");
  const [items, setItems] = React.useState<any[]>([]);

  const onSelect = (label: string) => {
    const _ = label.toLowerCase();
    if (_ === "all") {
      navigate("/work/");
    } else {
      navigate(`/work/?category=${_}`);
    }
  };

  React.useEffect(() => {
    const query = new URLSearchParams(location.search);
    const c = query.get("category") ?? "all";
    setCategory(c);

    const tmp = data?.allWpWork?.nodes ?? [];
    if (c !== "all") {
      const _ = tmp.filter((v) => v.project_category.category?.includes(c));
      setItems(_);
    } else {
      setItems(tmp);
    }
  }, [location]);

  return (
    <Layout title="PROJECT">
      <main
        css={css`
          padding-top: 88px;
          @media (min-width: 767px) {
            padding-top: 147px;
          }
          @media (min-width: 1299px) {
            padding-top: 159px;
          }
        `}
      >
        <div style={{ margin: "0 20px" }}>
          <div
            style={{
              display: "flex",
              gap: "10px",
              margin: "0 0 20px",
            }}
          >
            <WorkCategoryItem
              label="ALL"
              current={category}
              onSelect={onSelect}
            />
            <WorkCategoryItem
              label="IDENTITY"
              current={category}
              onSelect={onSelect}
            />
            <WorkCategoryItem
              label="PRINT"
              current={category}
              onSelect={onSelect}
            />
            <WorkCategoryItem
              label="DIGITAL"
              current={category}
              onSelect={onSelect}
            />
            <WorkCategoryItem
              label="SPATIAL"
              current={category}
              onSelect={onSelect}
            />
          </div>
          <WorkList path="/work/" items={items} />
        </div>
      </main>
    </Layout>
  );
};
export default WorkPage;

export const pageQuery = graphql`
  query {
    allWpWork(sort: { fields: [date], order: DESC }) {
      nodes {
        title
        title_en {
          titleEn
        }
        slug
        date
        project_category {
          category
        }
        thumbnail {
          thumbnailContent {
            type
            movie {
              guid
            }
            images {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                    fluid {
                      originalImg
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
