import * as React from "react";
import MediaQuery from "react-responsive";
import WorkList1 from "./work-list-1";
import WorkList3 from "./work-list-3";

type DataProps = {
  color?: string;
  path: string;
  items: any;
};

const WorkList = ({ color, path, items }: DataProps): JSX.Element => {
  return (
    <>
      <MediaQuery maxWidth={767}>
        <WorkList1 color={color} path={path} data={items} />
      </MediaQuery>
      <MediaQuery minWidth={768}>
        <WorkList3 color={color} path={path} data={items} />
      </MediaQuery>
    </>
  );
};

export default WorkList;
